<template>
    <div id="app">
      <header>
        <div class="container header">
          <img src="@/assets/logo.png" alt="Logo GR Team" class="logo" />
          <button class="button-outline">Acessar plataforma</button>
        </div>
      </header>
  
      <main>
        <section class="hero">
          <div class="hero-content container">
            <img src="@/assets/user-placeholder.png" alt="User" class="hero-image" />
            <h1>Faça parte dos melhores Freelancer do mundo</h1>
            <p>
              Conecte-se com empresas globais e tenha acesso a oportunidades exclusivas para desenvolver sua carreira como freelancer.
            </p>
            <p>Junte-se a uma comunidade de talentos e eleve seus projetos ao próximo nível!</p>
            <div class="hero-buttons">
              <button class="button-primary">Fazer cadastro</button>
              <button class="button-secondary">Acessar plataforma</button>
            </div>
          </div>
        </section>
  
        <section class="partners">
          <div class="container">
            <h2>Conheça quem confia em nós</h2>
            <div class="partners-logos">
              <img src="@/assets/logo1.png" alt="Parceiro 1" />
              <img src="@/assets/logo2.png" alt="Parceiro 2" />
              <img src="@/assets/logo3.png" alt="Parceiro 3" />
              <img src="@/assets/logo4.png" alt="Parceiro 4" />
            </div>
          </div>
        </section>
      </main>
  
      <footer>
        <div class="container footer-content">
          <p>&copy;2024 GR Team | Todos os direitos reservados.</p>
          <p>
            <a href="#">Política de privacidade</a> | <a href="#">Termos de uso</a>
          </p>
        </div>
      </footer>
    </div>
  </template>
  
  <!-- <script>
  export default {
    name: 'HomePage'
  }
  </script> -->
  
  
  <style scoped>
  /* Layout base */
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
  }
  
  /* Header */
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
  }
  .logo {
    height: 50px;
  }
  .button-outline {
    padding: 10px 20px;
    border: 2px solid #42b983;
    color: #42b983;
    background: transparent;
    border-radius: 5px;
    cursor: pointer;
  }
  
  /* Hero Section */
  .hero {
    text-align: center;
    padding: 60px 0;
    background: linear-gradient(to right, #e0f7fa, #ffffff);
  }
  .hero-image {
    border-radius: 50%;
    width: 100px;
    height: 100px;
    margin-bottom: 20px;
  }
  .hero h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #333;
  }
  .hero p {
    font-size: 1rem;
    color: #555;
    margin-bottom: 20px;
  }
  .hero-buttons {
    display: flex;
    gap: 20px;
    justify-content: center;
  }
  .button-primary {
    padding: 10px 20px;
    color: #fff;
    background-color: #42b983;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  .button-secondary {
    padding: 10px 20px;
    color: #42b983;
    background-color: transparent;
    border: 2px solid #42b983;
    border-radius: 5px;
    cursor: pointer;
  }
  
  /* Partners Section */
  .partners {
    padding: 40px 0;
    background-color: #e0f7fa;
    text-align: center;
  }
  .partners h2 {
    margin-bottom: 20px;
    color: #333;
  }
  .partners-logos {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
  }
  .partners-logos img {
    width: 150px;
    height: auto;
  }
  
  /* Footer */
  .footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
    color: #999;
  }
  .footer-content a {
    color: #999;
    text-decoration: none;
  }
  .footer-content a:hover {
    text-decoration: underline;
  }
  
  /* Responsividade */
  @media (max-width: 768px) {
    .hero h1 {
      font-size: 2rem;
    }
    .hero p {
      font-size: 0.9rem;
    }
    .hero-buttons {
      flex-direction: column;
    }
    .partners-logos img {
      width: 100px;
    }
    .footer-content {
      flex-direction: column;
      text-align: center;
    }
  }
  </style>
  

